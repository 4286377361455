<!--校长信箱 导出word 模板-->
<template>
  <div id="mailboxPrintTemp" class="principal-mailbox-docx">
    <h2 class="title">
      校长信箱反馈事项登记表</h2>
    <br/>
    <br/>
    <table class="table" border="1"
           style=" width: 100%; border-collapse: collapse; border: 1px solid #000; table-layout: fixed;">
      <colgroup>
        <col style="width:120px;" />
        <col />
        <col style="width:120px;" />
        <col />
      </colgroup>
      <tr>
        <td class="font-right" style="width:120px;white-space: nowrap">反馈人</td>
        <td>{{ formData.userName }}</td>
        <td class="font-right">部门/学院</td>
        <td>{{ formData.collegeName ? formData.collegeName : (formData.deptName ? formData.deptName : '') }}</td>
      </tr>
      <tr>
        <td class="font-right" style="width:120px;">班级</td>
        <td>{{ formData.classroomName }}</td>
        <td class="font-right">联系电话</td>
        <td>{{ formData.phone }}</td>
      </tr>
      <tr>
        <td class="font-right">反馈时间</td>
        <td>{{ formData.createTime| formatDate('YYYY-MM-DD HH:mm') }}</td>
        <td class="font-right">反馈部门</td>
        <td>{{ formData.deptName }}</td>
      </tr>
      <tr>
        <td class="font-right">反馈内容</td>
        <td colspan="3" style="word-break: break-all;white-space: normal;">{{ formData.content }}</td>
      </tr>
      <tr>
        <td class="font-right">反馈图片</td>
        <td colspan="3">
          <div v-if="formData.imgPathList.length">
            <img v-for="(imgUrl , index) in formData.imgPathList" :key="index" :src="`${$fileUrl}${imgUrl}`"
                 style="display:inline-block;margin-right: .2em;width: 4em;height: auto;" width="200" height="auto" alt="">
          </div>
        </td>
      </tr>
      <tr>
        <td class="font-right">处理回复</td>
        <td colspan="3" style="word-break: break-all;white-space: normal;">{{ formData.teachFeedback }}</td>
      </tr>
      <tr>
        <td class="font-right">处理回复时间</td>
        <td colspan="3">
          <div v-if="formData.feedbackTime">{{ formData.feedbackTime | formatDate('YYYY-MM-DD HH:mm') }}</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getFeedbackByIdApi } from '@/api/feedback'
import { saveAs } from 'file-saver'
import htmlDocx from 'html-docx-js/dist/html-docx'

export default {
  name: 'principalMailBoxDocx',
  data () {
    return {
      id: '',
      formData: {
        id: null,
        userName: '',
        collegeName: '',
        deptName: '',
        imgPathList: [],
        classroomName: '',
        phone: '',
        createTime: '',
        content: '',
        teachFeedback: '',
        feedbackTime: ''
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataList()
      }
    })
  },
  methods: {
    // 获取反馈详情
    getDataList () {
      getFeedbackByIdApi(this.id).then((res) => {
        this.formData = res.data
      }).then(() => {
        // 把获取到的html放入到原生的html中
        const _html = document.querySelector('#mailboxPrintTemp')
        const htmlStr = `
          <!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8">
              <style>
                body{
                  font-size: 1.2em;
                  font-family:'fangsong';
                  mso-ascii-font-family:'Times New Roman';
                }
                .title{
                  text-align: center;
                  font-size: 2.8em;
                }
                table td {
                    border: 1px solid #000;
                    padding: 1em;
                  }
                  table td img{
                    width: 4rem;
                  }
                .font-right{text-align: right}
              </style>
            </head>
            <body style="font-family:'fangsong';mso-ascii-font-family:'Times New Roman';">
              ${_html.innerHTML}
            </body>
          </html>`
        // 导出为word
        saveAs(
          htmlDocx.asBlob(htmlStr, { orientation: 'portrait' }),
          `校长信箱(反馈部门_${this.formData.deptName}).docx`
        )
      }).catch((e) => {
        if (!e) {
          this.$message.error('获取失败，请重试！')
        }
      }).finally(() => {
        this.close()
      })
    },
    nodeToString (node) {
      let tmpNode = document.createElement('div')
      tmpNode.appendChild(node.cloneNode(true))
      const str = tmpNode.innerHTML
      tmpNode = node = null
      return str
    },
    close () {
      this.$parent.$parent.visibleDocx = false
    },
    setInitData () {
    }
  }
}
</script>

<style lang="scss">
.principal-mailbox-docx {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;

  table.table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
    table-layout: fixed;

    td {
      border: 1px solid #000;
      padding: 10px;
    }
  }
}
</style>
