<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form ref="searchFormRef" :queryInfo.sync="queryInfo" @on-search="renderTable(1)">
        <el-form-item label="时间范围" prop="startDate">
          <el-row>
            <el-col :span="11">
              <el-date-picker type="datetime" size="small" placeholder="开始时间" v-model="queryInfo.startTime"
                style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
            <el-col class="line" style="text-align: center" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker type="datetime" size="small" placeholder="结束时间" v-model="queryInfo.endTime"
                style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="deptId" label="反馈部门">
          <el-select size="small" v-model="queryInfo.deptId" label="处理状态">
            <el-option v-for="{ deptName, id } in departmentList" :key="id" :label="deptName" :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="处理状态">
          <el-select size="small" v-model="queryInfo.status">
            <el-option label="已处理" :value="1" />
            <el-option label="未处理" :value="0" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="selection" width="40" />
      <el-table-column type="index" label="ID" width="70" align="center" />
      <el-table-column prop="createTime" label="时间" width="160" />
      <el-table-column prop="deptName" label="反馈部门" width="140" />
      <el-table-column prop="userName" label="反馈人" width="80" />
      <el-table-column prop="collegeName" label="反馈人班级/学院" width="220">
        <template v-slot="{ row }">
          {{ row.className ? row.className : (row.collegeName ? row.collegeName : '--') }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="反馈内容" min-width="230" />
      <el-table-column label="反馈图片" width="100">
        <template v-slot="{ row }">
          <div class="image">
            <el-image v-for="(imgUrl, index) in row.imgPathList" :key="index"
              style="width: 100%; height: auto; margin-right: 6px" :src="$fileUrl + '' + imgUrl"
              :preview-src-list="[$fileUrl + '' + imgUrl]" fit="contain" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="处理人部门/院系" width="150">
        <template v-slot="{ row }">
          {{ row.teachDeptName ? row.teachDeptName : row.teachCollegeName }}
        </template>
      </el-table-column>
      <el-table-column prop="teachFeedback" label="处理结果" />
      <el-table-column prop="createTime" label="处理图片" width="100">
        <template v-slot="{ row }">
          <div class="image">
            <el-image v-for="(imgUrl, index) in row.teachImgPathList" :key="index"
              style="width: 100%; height: auto; margin-right: 6px" :src="$fileUrl + '' + imgUrl"
              :preview-src-list="[$fileUrl + '' + imgUrl]" fit="contain" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button type="primary" size="mini" v-permission="['principalMailBox:review']" v-if="row.status === 0"
            @click="showDialog(row.id)">
            处理
          </el-button>
          <el-button type="primary" plain size="mini" v-if="row.status" @click="showDialog(row.id)">查看</el-button>
          <el-button type="primary" size="mini"
            @click="$http.exportWordHttp.exportPrincipalMailBox(row.id, `校长信箱(反馈部门_${row.deptName})`)">
            导出word
          </el-button>
          <el-button v-permission="['principalMailBox:del']" size="mini" type="danger"
            @click="del(deleteFeedback, row.id, `反馈人-${row.userName}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <template v-show="false">
      <principal-mail-box-docx ref="mailDocxRef" v-if="visibleDocx" />
    </template>
    <principal-mail-box-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
      @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getFeedbackList, deleteFeedback } from '@/api/feedback'
import tableView from '@/vue/mixins/table-view'
import PrincipalMailBoxDialog
  from '@/views/pages/principal-office/principal-mail-box/component/principalMailBoxDialog.vue'
import PrincipalMailBoxDocx from '@/views/pages/principal-office/principal-mail-box/component/principalMailBoxDocx.vue'

export default {
  name: 'feedback',
  mixins: [tableView],
  components: {
    PrincipalMailBoxDocx,
    PrincipalMailBoxDialog
  },
  data() {
    return {
      visibleDocx: false,
      queryInfo: {
        startTime: null,
        endTime: null,
        status: null,
        deptId: null
      },
      departmentList: []
    }
  },
  mounted() {
    this.getDepartmentList()
    this.renderTable()
  },
  methods: {
    deleteFeedback, // 删除api
    // 获取部门
    getDepartmentList() {
      this.$http.getDepartmentListQuery({}).then((res) => {
        this.departmentList = res.data
      })
    },
    // 获取系统反馈列表
    renderTable(pageNum) {
      this.getTableData(getFeedbackList, pageNum)
    },
    exportDocx(row) {
      this.$http.exportPrincipalMailBox(row.id, `校长信箱(反馈部门_${row.deptName})`)
      // await this.showSubDialog('visibleDocx', 'mailDocxRef', row.id)
    }
  }
}
</script>
