import request from '@/service/request'

// 获取消息通知列表
export const getFeedbackList = (params) => {
  return request.post('/app/sysFeedback/page', params)
}

// 添加消息通知
export const saveFeedbackApi = (params) => {
  return request.post('/app/sysFeedback/save', params)
}

// 删除消息通知
export const deleteFeedback = (params) => {
  return request.post('/app/sysFeedback/del/' + params)
}

// 根据id获取消息通知信息
export const getFeedbackByIdApi = (params) => {
  return request.post('/app/sysFeedback/getById/' + params)
}
