var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"queryInfo":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"时间范围","prop":"startDate"}},[_c('el-row',[_c('el-col',{attrs:{"span":11}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetime","size":"small","placeholder":"开始时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.queryInfo.startTime),callback:function ($$v) {_vm.$set(_vm.queryInfo, "startTime", $$v)},expression:"queryInfo.startTime"}})],1),_c('el-col',{staticClass:"line",staticStyle:{"text-align":"center"},attrs:{"span":2}},[_vm._v("-")]),_c('el-col',{attrs:{"span":11}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"datetime","size":"small","placeholder":"结束时间","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.queryInfo.endTime),callback:function ($$v) {_vm.$set(_vm.queryInfo, "endTime", $$v)},expression:"queryInfo.endTime"}})],1)],1)],1),_c('el-form-item',{attrs:{"prop":"deptId","label":"反馈部门"}},[_c('el-select',{attrs:{"size":"small","label":"处理状态"},model:{value:(_vm.queryInfo.deptId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "deptId", $$v)},expression:"queryInfo.deptId"}},_vm._l((_vm.departmentList),function(ref){
var deptName = ref.deptName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":deptName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"prop":"status","label":"处理状态"}},[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.queryInfo.status),callback:function ($$v) {_vm.$set(_vm.queryInfo, "status", $$v)},expression:"queryInfo.status"}},[_c('el-option',{attrs:{"label":"已处理","value":1}}),_c('el-option',{attrs:{"label":"未处理","value":0}})],1)],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"selection","width":"40"}}),_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"70","align":"center"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"时间","width":"160"}}),_c('el-table-column',{attrs:{"prop":"deptName","label":"反馈部门","width":"140"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"反馈人","width":"80"}}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"反馈人班级/学院","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className ? row.className : (row.collegeName ? row.collegeName : '--'))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"content","label":"反馈内容","min-width":"230"}}),_c('el-table-column',{attrs:{"label":"反馈图片","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"image"},_vm._l((row.imgPathList),function(imgUrl,index){return _c('el-image',{key:index,staticStyle:{"width":"100%","height":"auto","margin-right":"6px"},attrs:{"src":_vm.$fileUrl + '' + imgUrl,"preview-src-list":[_vm.$fileUrl + '' + imgUrl],"fit":"contain"}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"处理人部门/院系","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.teachDeptName ? row.teachDeptName : row.teachCollegeName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"teachFeedback","label":"处理结果"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"处理图片","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"image"},_vm._l((row.teachImgPathList),function(imgUrl,index){return _c('el-image',{key:index,staticStyle:{"width":"100%","height":"auto","margin-right":"6px"},attrs:{"src":_vm.$fileUrl + '' + imgUrl,"preview-src-list":[_vm.$fileUrl + '' + imgUrl],"fit":"contain"}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"220","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['principalMailBox:review']),expression:"['principalMailBox:review']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v(" 处理 ")]):_vm._e(),(row.status)?_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id)}}},[_vm._v("查看")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.$http.exportWordHttp.exportPrincipalMailBox(row.id, ("校长信箱(反馈部门_" + (row.deptName) + ")"))}}},[_vm._v(" 导出word ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['principalMailBox:del']),expression:"['principalMailBox:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.deleteFeedback, row.id, ("反馈人-" + (row.userName)))}}},[_vm._v("删除 ")])]}}])})],1),[(_vm.visibleDocx)?_c('principal-mail-box-docx',{ref:"mailDocxRef"}):_vm._e()],(_vm.visibleDialog)?_c('principal-mail-box-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }