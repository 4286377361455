<template>
  <el-dialog class="principal-mail-box-dialog" :title="`${formData.status ? '查看' : '处理'}校长信箱`" :visible.sync="show"
    :close-on-click-modal="false" width="720px">
    <el-divider content-position="left">反馈信息</el-divider>
    <el-descriptions :column="2" border>
      <el-descriptions-item label-align="right" label-class-name="descriptions-item__label">
        <template slot="label">
          <i class="el-icon-user"></i>
          反馈人
        </template>
        {{ readData.userName }}
      </el-descriptions-item>
      <el-descriptions-item v-if="readData.classroomName" label-align="right" label-class-name="descriptions-item__label">
        <template slot="label">
          <i class="el-icon-s-order"></i>
          反馈人班级
        </template>
        {{ readData.classroomName }}
      </el-descriptions-item>
      <el-descriptions-item v-if="!readData.classroomName && readData.collegeName" label-align="right"
        label-class-name="descriptions-item__label">
        <template slot="label">
          <i class="el-icon-building"></i>
          反馈人院系
        </template>
        {{ readData.collegeName }}
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label-class-name="descriptions-item__label" :span="2">
        <template slot="label">
          <i class="el-icon-suitcase-1"></i>
          反馈部门
        </template>
        {{ readData.deptName }}
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label-class-name="descriptions-item__label" :span="2">
        <template slot="label">
          <i class="el-icon-picture-outline"></i>
          反馈图片
        </template>
        <div class="image" v-if="readData.imgPathList.length">
          <el-image v-for="(imgUrl, index) in readData.imgPathList" :key="index"
            style="width: 140px; height: auto; margin-right: 6px" :src="$fileUrl + '' + imgUrl"
            :preview-src-list="[$fileUrl + '' + imgUrl]" fit="contain" />
        </div>
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label-class-name="descriptions-item__label" :span="2">
        <template slot="label">
          <i class="el-icon-tickets"></i>
          反馈内容
        </template>
        <p>{{ readData.content }}</p>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">处理反馈</el-divider>
    <!-- 只读时 -->
    <el-descriptions :column="1" border v-if="readData.status > 0">
      <el-descriptions-item label-align="right" label="处理人" label-class-name="descriptions-item__label">
        {{ readData.teachUserRealName }}
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label="处理部门/院系" label-class-name="descriptions-item__label">
        {{ readData.teachDeptName ? readData.teachDeptName : readData.teachCollegeName }}
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label="处理时间" label-class-name="descriptions-item__label">
        {{ readData.feedbackTime }}
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label="处理图片" label-class-name="descriptions-item__label">
        <div class="image" v-if="readData.teachImgPathList.length">
          <el-image v-for="(imgUrl, index) in readData.teachImgPathList" :key="index"
            style="width: 140px; height: auto; margin-right: 6px" :src="$fileUrl + '' + imgUrl"
            :preview-src-list="[$fileUrl + '' + imgUrl]" fit="contain" />
        </div>
      </el-descriptions-item>
      <el-descriptions-item label-align="right" label="处理结果" label-class-name="descriptions-item__label">
        <p>{{ readData.teachFeedback }}</p>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" border v-else-if="readData.status === -1">
      <el-descriptions-item label-align="right" label="状态" label-class-name="descriptions-item__label">
        <el-tag type="info">已撤回</el-tag>
      </el-descriptions-item>
    </el-descriptions>
    <!-- 处理时 -->
    <el-form ref="form" v-loading="loadData.loading" v-else :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="处理图片" prop="teachImgPathList">
        <multiple-img-uploader :urlList.sync="formData.teachImgPathList" />
      </el-form-item>
      <el-form-item label="处理结果" prop="teachFeedback">
        <el-input type="textarea" rows="3" v-model.trim="formData.teachFeedback" placeholder="请输入处理结果" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">关闭</el-button>
      <el-button v-if="!readData.status" @click="save" type="primary">处理</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getFeedbackByIdApi, saveFeedbackApi } from '@/api/feedback'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'principalMailBoxDialog',
  props: {
    addData: {
      type: Object
    }
  },
  mixins: [dialog],
  data() {
    return {
      readData: {
        id: '',
        imgPathList: [],
        deptId: '',
        content: '',
        imgPath: '',
        userName: '',
        deptName: '',
        classroomName: '', // 反馈人
        collegeName: '', // 反馈人
        status: 1,
        teachUserRealName: '', // 处理人
        teachDeptName: '', // 处理人部门
        teachCollegeName: '', // 处理人院系
        teachFeedback: '', // 处理反馈
        feedbackTime: '', // 处理时间
        teachImgPathList: [] // 处理图片
      },
      formData: {
        id: '',
        teachImgPathList: [],
        teachFeedback: ''
      },
      rules: {
        teachFeedback: {
          required: true,
          trigger: 'blur',
          message: '请输入处理结果!'
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getDataList()
    })
  },
  methods: {
    // 添加评价
    save() {
      this.formData.id = this.id
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadData.loading = true
          saveFeedbackApi(this.formData).then((res) => {
            if (res.success) {
              this.$message.success('系统反馈处理成功!')
              this.closed()
            }
          }).catch().finally(() => {
            this.loadData.loading = false
          })
        } else {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
      })
    },
    // 获取反馈详情
    getDataList() {
      getFeedbackByIdApi(this.id).then((res) => {
        Object.keys(this.readData).forEach(key => {
          this.readData[key] = res.data[key]
        })
      })
    }
  }
}
</script>
<style lang="scss">
::v-deep .el-scrollbar__wrap {
  margin-bottom: 0 !important;
}

.principal-mail-box-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
